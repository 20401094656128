<template>
	<div class="page">
		<div class="container">

			<div class="btns">
				<div class="btn" v-if="$store.state.userinfo" @click="loginout">退出</div>
				<div class="btn" @click="login()" v-else>登录</div>

				<div class="btn" @click="bind()">{{ device }}</div>
			</div>
			<!-- <div class="box">
				<div class="box1">
					<div class="item" @click="cloud">我的云</div>
					<div class="item" @click="go('/Shop')">商城</div>
				</div>
				<div class="box2">
					<div class="item" @click="go('/fileUpload/file')">文件打印</div>
					<div class="item" @click="go('/fileUpload/pic')">照片打印</div>
					<div class="item" @click="go('/fileUpload/zjz')">证件照打印</div>
					<div class="item" @click="go('/Scanning')">身份证复印</div>
					<div class="item" @click="go('/ScanningFile')">文件复印</div>
					<div class="item" @click="go('/ScanningFilePdf')">文件扫描</div>
				</div>
			</div>
 -->
            <div class="box">
				<div class="item" @click="cloud">
					<i class="iconfont icon-cloud-search"></i>
					<p>我的云</p>
				</div>
				
				<div class="item" @click="go('/fileUpload/file')">
					<i class="iconfont icon-24gl-fileText"></i>
					<p>文件打印</p>
				</div>
				
				<div class="item" @click="go('/fileUpload/pic')">
					<i class="iconfont icon-charutupian"></i>
					<p>照片打印</p>
				</div>
				
				<div class="item" @click="go('/fileUpload/zjz')">
					<i class="iconfont icon-icon-"></i>
					<p>证件照打印</p>
				</div>
			<!-- 	
				<div class="item" @click="go('/Scanning')">
					<i class="iconfont icon-shenfenzheng"></i>
					<p>身份证复印</p>
				</div>
				 -->
				<div class="item" @click="go('/select')">
					<i class="iconfont icon-shenfenzheng"></i>
					<p>身份证复印</p>
				</div>
				
				<div class="item" @click="go('/ScanningFile')">
					<i class="iconfont icon-dayinji_o"></i>
					<p>文件复印</p>
				</div>
				
				<div class="item" @click="go('/ScanningFilePdf')">
					<i class="iconfont icon-saomiaoyi"></i>
					<p>文件扫描</p>
				</div>
				
				<div class="item" @click="go('/Shop')">
					<i class="iconfont icon-shangcheng"></i>
					<p>商城</p>
				</div>
			</div>
 
 
            
              
			<el-dialog title="提示" :visible.sync="showLogin" width="450px" height="600px"
				>
				<iframe :src="src" frameborder="0" style="width:400px; height:450px"></iframe>
				<span slot="footer" class="dialog-footer">
					<el-button @click="showLogin = false">关闭</el-button>
				</span>
			</el-dialog>

		</div>





	</div>
</template>

<script>
	//import  { getLodop }  from '../../utils/LodopFuncs.js' //导入模块
	import axios from 'axios'
	/* import {
		getLodop
	} from '../../utils/LodopFuncs' */
	export default {
		name: 'Home',

		data() {
			return {
				code: require("@/assets/images/code.png"),
				photo: require('@/assets/photo/pic.jpg'),
				dialogVisible: false,
				list: [],
				name: '',
				userInfo: null,
				token: '',
				device: '绑定设备',
				showLogin: false,
				src: 'https://open.weixin.qq.com/connect/qrconnect?appid=wx9190d04b49c38d18&redirect_uri=https%3A%2F%2Fprint.ynlothar.com%2Fapi%2Flogin%2FPcScanUrl&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect'
                
			}
		},
		created() {
			console.log('aa', localStorage.getItem('device'))
			if (localStorage.getItem('device')) {
				this.device = localStorage.getItem('device');
				
			}


			this.userInfo = this.$store.state.userinfo;

			if (!this.userInfo) {
				this.token = this.$route.query.token;
				if (this.token) {
					sessionStorage.setItem('token', this.$route.query.token);

					this.getUserInfo()
				}/* else {
					console.log(555)
					this.token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhZG1pbiIsImV4cCI6MTY1OTMzNjk3MSwiYXVkIjoiYWRtaW4iLCJuYmYiOjE2NTg3MzIxNzEsImlhdCI6MTY1ODczMjE3MSwidXNlcl9pZCI6ODEsInJvbGVfaWQiOjQsImFkbWluIjp0cnVlfQ.UsS97avgyKblZMb_RHFubc8CJ973N-HHGHEixPjEDfg";
					sessionStorage.setItem('token', this.token);
					this.getUserInfo()
				} */

			}








			/* 	if(!sessionStorage.getItem('token')){
				this.token = this.$route.query.token;
				sessionStorage.setItem('token',this.$route.query.token);
			}else{
				this.token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhZG1pbiIsImV4cCI6MTY0NzQzNTkyMywiYXVkIjoiYWRtaW4iLCJuYmYiOjE2NDY4MzExMjMsImlhdCI6MTY0NjgzMTEyMywidXNlcl9pZCI6MzQsInJvbGVfaWQiOjEsImFkbWluIjp0cnVlfQ._pMMB3t4rsNAqho9XV3eJMV-QpezJvtDve5CkooVVL4";
				sessionStorage.setItem('token',this.$route.query.token);
			}
			
			this.getUserInfo()
			

			this.TaskList(); //任务列表
			if (sessionStorage.getItem("show") == 1) {
				this.dialogVisible = false;
			}
 */

		},
		methods: {
			login() {
				/* location.href =
					"https://open.weixin.qq.com/connect/qrconnect?appid=wx9190d04b49c38d18&redirect_uri=https%3A%2F%2Fprint.ynlothar.com%2Fapi%2Flogin%2FPcScanUrl&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect";
			 */
			
			  this.showLogin = true;
			
			},




			bind() {
				this.$prompt('请输入设备链接', '设备绑定', {
					confirmButtonText: '绑定',
					cancelButtonText: '取消',
				}).then(async ({
					value
				}) => {
					//   axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
					let res = await axios.get('https://' + value + '&type=2')
					console.log('res', res)
					if (res.data.code == 200) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						localStorage.setItem('device', res.data.data) //设备编号
						localStorage.setItem('model', res.data
							.model) //设备1用的EPSON WF-C5290 Series可打印照片纸    设备2可打印a3 a4纸张
						localStorage.removeItem('code')
						localStorage.removeItem('pic_code')
						localStorage.removeItem('file_code')
						localStorage.removeItem('zjz_code')
						localStorage.removeItem('index_code')
						this.device = res.data.data

					} else {
						this.$message({
							type: 'error',
							message: res.data.msg
						});
					}

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消绑定'
					});
				});
			},


			//我的云
			cloud() {

				if (this.$store.state.userinfo) {
					this.go('/MyCloud')
				} else {
					this.$message.info('请先登录');
					setTimeout(() => {
						this.login();
					}, 2000)

				}

			},


			handleClose() {
				console.log('关闭')
				sessionStorage.setItem("show", 1);
				this.dialogVisible = false;
			},


			async getUserInfo() {
				console.log(333444)
				let res = await this.$api.getUserInfo();
				console.log('res', res)
				sessionStorage.setItem('name', res.data.nick_name)
				this.name = res.data.nick_name;
				console.log('用户信息', res.data);
				this.$store.commit('setUserinfo', res.data);
				
				 console.log('跳转了')
				//	top.location.href = 'https://pcprint.ynlothar.com/#/MyCloud'; 
			      
				    
				
				
				//this.$router.push('/MyCloud')
			},

			async TaskList() {
				try {
					let res = await this.$api.TaskList();
					if (res.code == 200) {
						console.log('aa', res.data.rows)
						this.list = res.data.rows;
					}
				} catch (err) {
					this.$message.error('数据请求失败');
				}
			},

			print(obj, type) {
				console.log(11, obj);
				//return false;
				if (obj.task_type == 3 || obj.task_type == 5) { //照片    和身份证
					this.photoPrint(obj, type);
				} else {
					this.filePrint(obj, type);
				}

			},

			//照片打印
			photoPrint(obj, type) {
				//console.log('打印', obj)
				//return false;
				if (obj.task_type == 5) { //身份证
					let LODOP = getLodop();
					LODOP.ADD_PRINT_IMAGE("0%", "0%", "100%", "100%", `<img src=${obj.print_file}>`);
					LODOP.SET_PRINT_STYLEA(0, "Stretch", 1);
					if (type == 1) {
						LODOP.PRINT();
					} else {
						LODOP.PRINT();
						let data = {
							'task_number': obj.task_number,
							'device_number': localStorage.getItem('device')
						};
						this.accomplish(data);
					}
				} else {
					var boxname = "";
					console.log(obj.print_type);

					switch (obj.print_type) {
						case 3: //一寸
							boxname = 'box1'
							break;
						case 4: //两寸
							boxname = 'box2'
							break;
						case 5: //大一寸
							boxname = 'box3'
							break;
						case 6: //大两寸
							boxname = 'box4'
							break;
					}

					this.photo = obj.print_file;

					this.$nextTick(() => {
						var str = document.getElementById(boxname).innerHTML;
						let LODOP = getLodop();
						LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['HiTi P525L']);
						LODOP.ADD_PRINT_IMAGE(0, 0, "100%", "100%", str);
						LODOP.SET_PRINT_STYLEA(0, "Stretch", 1);

						console.log(2222)
						if (type == 1) {
							LODOP.PRINT();
						} else {
							LODOP.PRINT();
							let data = {
								'task_number': obj.task_number,
								'device_number': localStorage.getItem('device')
							};
							this.accomplish(data);
						}

					})

				}



			},

			//文件打印
			filePrint(obj, type) {
				let LODOP = getLodop();
				console.log('wd11', obj);
				return false;

				LODOP.PRINT_INIT("测试PDF打印功能");

				LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);
				console.log(44444)

				console.log(2233, this.demoDownloadPDF(obj.print_file))

				LODOP.ADD_PRINT_PDF(0, 0, "100%", "100%", this.demoDownloadPDF(obj.print_file));
				if (obj.print_ctype == 2) {
					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
				}

				LODOP.SET_PRINT_COPIES(obj.print_number); //打印份数
				//LODOP.SET_PRINT_STYLE('FontColor','#000')
				LODOP.SET_PRINT_STYLEA(0, "FontColor", "#808080");
				//LODOP.SET_PRINT_MODE("COLOR",false);
				//	LODOP.SET_PRINT_COLOR(1);//指定份数
				if (type == 1) {
					LODOP.PRINT();

				} else {
					console.log('文件打印');
					LODOP.PRINT();
					let data = {
						'task_number': obj.task_number,
						'device_number': localStorage.getItem('device')
					};
					this.accomplish(data);
				}
			},



			async accomplish(data) {

				let res = await this.$api.accomplish(data);
				this.TaskList();
			},


			demoDownloadPDF(url) {
				if (!(/^https?:/i.test(url))) return;
				if (window.XMLHttpRequest) var xhr = new XMLHttpRequest();
				else var xhr = new ActiveXObject("MSXML2.XMLHTTP");
				xhr.open('GET', url, false); //同步方式
				if (xhr.overrideMimeType)
					try {
						xhr.responseType = 'arraybuffer';
						var arrybuffer = true;
					} catch (err) {
						xhr.overrideMimeType('text/plain; charset=x-user-defined');
					}
				xhr.send(null);
				var data = xhr.response || xhr.responseBody;
				if (typeof Uint8Array !== 'undefined') {
					if (arrybuffer) var dataArray = new Uint8Array(data);
					else {
						var dataArray = new Uint8Array(data.length);
						for (var i = 0; i < dataArray.length; i++) {
							dataArray[i] = data.charCodeAt(i);
						}
					}
				} else
					var dataArray = VBS_BinaryToArray(data).toArray(); //兼容IE低版本
				return this.demoGetBASE64(dataArray);
			},

			demoGetBASE64(dataArray) {
				var digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
				var strData = "";
				for (var i = 0, ii = dataArray.length; i < ii; i += 3) {
					if (isNaN(dataArray[i])) break;
					var b1 = dataArray[i] & 0xFF,
						b2 = dataArray[i + 1] & 0xFF,
						b3 = dataArray[i + 2] & 0xFF;
					var d1 = b1 >> 2,
						d2 = ((b1 & 3) << 4) | (b2 >> 4);
					var d3 = i + 1 < ii ? ((b2 & 0xF) << 2) | (b3 >> 6) : 64;
					var d4 = i + 2 < ii ? (b3 & 0x3F) : 64;
					strData += digits.substring(d1, d1 + 1) + digits.substring(d2, d2 + 1) + digits.substring(d3, d3 + 1) +
						digits.substring(d4, d4 + 1);
				}
				return strData;
			},

			loginout() {
				sessionStorage.setItem('token', '');
				sessionStorage.setItem('userInfo', null);
				this.$store.commit('setUserinfo', null);
				this.$message.success('用户退出成功');
				this.$router.push('/');
			},
			btnClickPrint: function() {

			},

			go(url) {
				this.$router.push({
					path: url,
				})
			},
			back() {
				this.$router.back();
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box {
		height: 60vh;

		overflow-y: auto;
		width: 1100px;
		margin: 0 auto;
		display: flex;

		align-content:flex-start;
		flex-wrap: wrap;
		margin-top: 5vh;
      /*  background-color: red; */
	
	   .item{
		   width:24%;
		   margin-right:1%;
		   margin-top: 15px;
		   height:250px;
		   background-color: yellow;
		   text-align: center;
		   cursor: pointer;
		   border-radius: 15px;
		   
		   background-color: rgba(68, 227, 24, 100);
		   
		   &:nth-child(2){
		   	background-color: rgba(89, 221, 241, 100);
		   }
		   
		   &:nth-child(3) {
		   	background-color: rgba(241, 120, 89, 100);
		   }
		   
		   &:nth-child(4) {
		   	background-color: rgba(217, 221, 40, 100);
		   }
		   
		   &:nth-child(5) {
		   	background-color: rgba(217, 221, 40, 100);
		   }
		   
		   &:nth-child(6){
		   	
				background-color: rgba(241, 120, 89, 100);
		   }
		   
		   &:nth-child(7) {
		   background-color: rgba(89, 221, 241, 100);
		   }
		   
		   &:nth-child(8) {
		   	 background-color: rgba(68, 227, 24, 100);
		   }
		   
		   .iconfont{
			   font-size: 80px;
			   font-weight: bold;
			   color: #fff;
			   line-height: 150px;
		   }
		   p{
			   
			    font-weight: bold;
			   font-size: 30px;
			   color:#fff;
		   }
	   }
	}

	.top {
		margin-top: -15px;
		height: 30px;
		color: #fff;
	}


	.left {
		float: left;
	}

	.right {
		float: right;
	}

	.btns {
		width: 400px;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		cursor: pointer;

		.btn {
			height: 35px;
			line-height: 35px;
			padding: 0 15px;
			background: #E35A5A;
			color: #fff;
			font-weight: 500;

			&:nth-child(2) {
				background-color: rgba(92, 219, 211, 100);
			}
		}
	}
</style>
